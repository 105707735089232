import * as React from "react";
import {useContext} from "react";

const routes = {
  home: "/",
  gemGlo: "/global/gem",
  gemEur: "/europe/gem",
  gemAt: "/austria/gem",
  cwmGlo: "/global/cwm",
  ewmAt: "/austria/ewm",
  ewmEur: "/europe/ewm",
  contact: "/contact",
  imprint: "/imprint",
  dataProtectionRegulation: "/data-protection-regulation",
};
export default routes;

export function getLocalizedRoute(route: string, intl) {
  const currentLocale = intl.locale;
  const defaultLocale = intl.defaultLocale;
  if (currentLocale && currentLocale !== defaultLocale) {
    return  "/" + currentLocale + route;
  } else {
    return route;
  }
}

export const LocationContext = React.createContext({defaultFromRoutes: 4});

export function useLocation() {
  return useContext(LocationContext);
}
